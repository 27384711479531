@import '@nike/nike-design-tokens/dist/scss/_tokens.scss';
@import 'constants/_variables';

.profileWrapper {
  justify-content: start;
  padding: 48px;
  min-height: calc(100vh - 408px);
  @media (max-width: $podium-cds-breakpoint-l) {
    min-height: calc(100vh - 365px);
  }
}
