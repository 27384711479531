@import '@nike/nike-design-tokens/dist/scss/_tokens.scss';
@import 'constants/_variables';

.CollapsedResponsesCard {
  cursor: pointer;

  background-color: $podium-cds-color-white !important;
  padding: 30px;
  margin: 5px;
  border-radius: 15px;
  box-shadow: 0px 2px 6px rgba(17, 17, 17, 0.08),
    0px 1px 2px rgba(17, 17, 17, 0.04);

  h1 {
    line-height: 1.5rem;
    font-size: 1.5rem;
    font-weight: 600;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ResponsesCard {
  cursor: pointer;
  width: 450px;
  background-color: $podium-cds-color-white !important;
  padding: 30px;
  margin: 5px;
  border-radius: 15px;
  box-shadow: 0px 2px 6px rgba(17, 17, 17, 0.08),
    0px 1px 2px rgba(17, 17, 17, 0.04);

  h1 {
    line-height: 1.5rem;
    font-size: 1.5rem;
    font-weight: 600;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.totals {
  display: flex;
  padding-top: 15px;
}

.total {
  text-align: center;
  margin: 10px 0;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.75rem;
  background-color: $podium-cds-color-grey-100;
}

.choices {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
  grid-gap: 8px;
  position: relative;
}
