@import '@nike/nike-design-tokens/dist/scss/_tokens.scss';
@import 'constants/_variables';

.ListPolls {
  padding: 15px;
}

.header {
  display: flex;
  justify-content: space-between;

  padding-bottom: 10px;
  h1 {
    padding-left: 10px;
    font-size: 1.5rem;
    font-weight: 500;
  }
}

.view {
  cursor: pointer;
}

.cards {
  display: flex;
  flex-wrap: wrap;
}

.list {
}
