@import '@nike/nike-design-tokens/dist/scss/_tokens.scss';
@import 'constants/_variables';

.card {
  border-radius: 10px;
  font-size: 18px;
  position: relative;
  z-index: 1;
  color: $podium-cds-color-white;

  h1 {
    font-size: 35px;
    line-height: 45px;
    font-weight: 500;
  }

  h4 {
    margin-top: 60px;
    font-weight: 500;
  }

  h6 {
  }
}

.textBlock {
  position: relative;
  z-index: 2;
}

.button {
  margin-top: 10px;
  color: $podium-cds-color-black;
  background-color: $podium-cds-color-white;
  border-color: $podium-cds-color-white;
  z-index: 2;
}

.gradient {
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(50, 50, 50, 0.4),
    rgba(50, 50, 50, 0.9)
  );
  z-index: 2;
}
