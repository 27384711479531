@import '@nike/nike-design-tokens/dist/scss/_tokens.scss';
@import 'constants/_variables';

/*************************
 *
 * Mobile First
 *
 *************************/

.bgImage {
  position: fixed;
  height: 100vh;
  height: 100svh;
  width: auto;
  min-width: 120vw;
  max-width: none;
  object-fit: cover;
}

.container {
  height: 100vh;
  height: 100svh;
  width: 100vw;
  padding: 0;
  margin: 0;

  background-color: var(--podium-cds-color-black);
  color: var(--podium-cds-color-white);

  --logo-animate-duration: 500ms;
  --logo-animate-delay: 1000ms;
  --text-animate-duration: 350ms;
  --text-animate-delay: 3000ms;
  --welcome-animate-delay: 100ms;
  --carousel-fade-in-duration: 700ms;
}

.welcomeContainer {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  animation: fadeIn var(--text-animate-duration) ease
      var(--welcome-animate-delay) forwards,
    fadeOut var(--text-animate-duration) ease
      calc(
        var(--logo-animate-duration) + var(--welcome-animate-delay) +
          var(--text-animate-delay)
      )
      forwards;
}

.swiper {
  width: 100vw;
  height: 100vh;
  height: 100svh;
  --swiper-pagination-top: calc(73vh + 52px);
  --swiper-pagination-top: calc(73svh + 52px);
  --swiper-pagination-bottom: none;

  :global(.swiper-wrapper),
  :global(.swiper-pagination) {
    opacity: 0;
    animation: fadeIn var(--carousel-fade-in-duration) ease
      calc(
        var(--logo-animate-duration) + var(--logo-animate-delay) +
          var(--welcome-animate-delay) + (var(--text-animate-duration) * 2) +
          var(--text-animate-delay)
      )
      forwards;
  }

  :global(.swiper-wrapper) {
    height: 33vh;
  }
}

.containerStart {
  max-width: 380px;
  margin: 40vh auto 0;
  margin: 37svh auto 0;
  padding: 0 32px;
}

.containerEnd {
  max-width: 380px;
  margin: 53px auto 0;
  padding: 0 32px;

  display: grid;
  grid-template-rows: 1fr;
  align-items: end;
}

.blackBackgroundFade {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  background-color: $podium-cds-color-black;
  animation: bgFade var(--logo-animate-duration) ease var(--logo-animate-delay)
    forwards;
  animation-fill-mode: forwards;
  opacity: 1;
}

.swoosh {
  position: relative;
  z-index: 3;
  left: 50%;
  transform: translate3d(-50%, 0, 0);

  width: 64px;
  height: auto;
  margin-left: -6px;
  margin-bottom: -12px;

  animation: swooshScale var(--logo-animate-duration) ease
    var(--logo-animate-delay) forwards;
  animation-fill-mode: forwards;
}

.bodyText {
  box-sizing: border-box;
  font: var(--podium-cds-typography-320-to-1919-title2);
  font-size: 28px;
  text-align: left;
  max-width: 380px;
  margin: 0 auto;
  padding: 0 32px;
}

.welcome {
  width: 250px;
}

.services,
.insight,
.legacy {
  width: 200px;
}

.signInButton {
  width: 100%;
}

@keyframes bgFade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes swooshScale {
  from {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  to {
    left: 0%;
    transform: translate3d(0%, 0, 0);
  }
}
