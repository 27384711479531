.container {
  background-color: var(--eds-color-background-default);
  color: var(--eds-color-text-secondary);
  padding: var(--eds-space-24);

  display: grid;
  grid-template-rows: 120px 1fr min-content;
  grid-row-gap: var(--eds-space-24);

  @media (min-width: 1440px) {
    min-width: 288px;
  }

  header {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: var(--eds-space-16);
    align-items: center;
  }

  li {
    padding: var(--eds-space-16) 0 var(--eds-space-12) var(--eds-space-12);
  }
}

.logo {
  line-height: 0;
}

.appName {
  color: var(--eds-color-grey-1);
  em {
    color: var(--eds-color-text-default);
    font-style: normal;
  }
}

.item {
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-column-gap: var(--eds-space-16);
  align-items: center;

  &:hover {
    color: var(--eds-color-text-default);
    text-decoration: underline;
  }

  &:focus {
    border: solid 1px var(--eds-color-border-focus);
    border-radius: var(--eds-radii-4);
    outline: none;
  }
}

.active {
  color: var(--eds-color-text-default);
}
