.container {
  height: calc(
    100vh - 133px
  ); /* 32px top margin + 22px bottom margin + 4px ProgressIndicator height + Onboarding headerWrapper margin (13px + 32px) + text height 28.8px */
  height: calc(100svh - 133px);
  overflow: auto;
  display: grid;
  grid-template-rows: calc(100% - 80px) 48px;
  grid-row-gap: 32px;
}

.header {
  margin-bottom: 20px;
}

.scrollBox {
  height: calc(
    100vh - 213px
  ); /* BasicViews height + button height + 32px padding */
  height: calc(100svh - 213px);
  overflow: auto;
}

input.otherField {
  background-color: var(--podium-cds-color-white);
}

.otherWrapper {
  button {
    color: var(--podium-cds-color-black);
  }
}

.darkBorder {
  border: 1px solid var(--podium-cds-color-black) !important;
}

.continueButton {
}
