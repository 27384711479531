@import '@nike/nike-design-tokens/dist/scss/_tokens.scss';
@import 'src/constants/_variables';

.code {
  margin: 0;
  padding: 15px;
  color: $podium-cds-color-grey-800;
  overflow: scroll;
  display: inline-block;
  border-radius: 3px;
  font-size: 85%;
  cursor: text;
  max-width: 100%;
}

.light {
  background: rgba(0, 0, 0, 0.5);
  color: $podium-cds-color-black;
}

.dark {
  background: rgba(57, 57, 59, 0.5);
  color: $podium-cds-color-white;
}

.inline {
  display: inline;
  padding: 0.2em 0.4em;
  white-space: normal;
  line-height: 2em;
}
