@import '@nike/nike-design-tokens/dist/scss/_tokens.scss';
@import 'src/constants/_variables';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
}

.header {
  color: $podium-cds-color-text-critical;
}

.message {
  max-width: 100%;
  overflow: scroll;
}

.stack {
  margin-top: 25px;
  max-width: 600px;
  overflow: scroll;
}

.buttons {
  margin-top: 25px;

  button {
    margin: 5px;
  }
}
