@import '@nike/nike-design-tokens/dist/scss/_tokens.scss';
@import 'constants/_variables';

.AthletePollHeader {
  position: relative;
  padding: $podium-cds-size-spacing-s $podium-cds-size-spacing-xl;
  display: grid;
  grid-template-columns: 100px 300px 600px 125px;
  align-items: center;
  background-color: $podium-cds-color-white;
  border: 1px solid $podium-cds-color-grey-100;
  box-shadow: var(--eds-elevation-shadow-4);
  min-height: 75px;
  color: $podium-cds-color-grey-500;
  font-size: 1rem;
}
