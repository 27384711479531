@import '@nike/nike-design-tokens/dist/scss/_tokens.scss';

.myCron,
.myCron * {
  display: flex;
  flex-direction: row;
  justify-content: normal;
  align-items: center;
  text-align: center;
  width: auto;
}

//.container {
//  height: calc(100vh - 84px);
//  width: calc(100vw - 84px);
//  //max-width: 90vw;
//  //margin: auto;
//  z-index: 1;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  //background-color: #f0f0f0;
//  background-color: #ffffff;
//}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  width: 100vw;
  height: 100vh;
  //
  //@media (min-width: 768px) {
  //  flex-direction: row;
  //  justify-content: space-around;
  //}
}
.adminWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  width: 100%;
  height: 100%;
}

.formStyle {
  background-color: $podium-cds-color-grey-100;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  min-width: 600px;
  overflow: auto;
  max-height: 90vh;
}

//.formStyle {
//  background-color: $podium-cds-color-grey-100;
//  padding: 20px;
//  border-radius: 8px;
//  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
//  min-width: 600px;
//}

.carousel {
  margin-top: 20px;
}

.button {
  margin-top: 20px;
}

.tabGroup {
  padding-left: 25px;
}

.table {
  position: relative;
  overflow: auto !important;
}

.tableRow {
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}

.headerRow {
  th {
    position: sticky;
    top: 0px;
    background-color: var(--podium-cds-color-grey-100);
    z-index: 2;
  }
}

.avatar {
  width: 75px;
  height: 75px;
  cursor: pointer;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
}

.interestsContainer {
  display: flex;
  max-width: 30vw;
  overflow: scroll;
}

.interest {
  display: inline-block;
  padding: 10px;
  border-radius: 2vh;
  width: fit-content;
  line-height: 22px;

  span {
    vertical-align: middle;
  }

  svg {
    vertical-align: middle;
    padding-right: 5px;
  }

  &::before {
    border-color: inherit;
  }

  background-color: $podium-cds-color-grey-100;
  display: flex;

  span {
    white-space: nowrap;
  }
}

.parent {
  display: inline-block;
  padding: 10px;
  border-radius: 2vh;
  width: 50px;
  line-height: 22px;
  text-align: center;
}

.yes {
  background-color: $podium-cds-color-green-100 !important;
}

.no {
  background-color: $podium-cds-color-red-100 !important;
}

.headshot {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  .field > div {
    width: 200px;
    height: 200px;
    margin: auto;
    padding: 3px;
    border-radius: 100px;
  }

  .placeholder,
  .preview img {
    width: 194px;
    height: 194px;
    border-radius: 100px;
  }
  .placeholder,
  .preview {
    position: relative;
    border-radius: 100px;
    color: $podium-cds-color-white;
    overflow: hidden;
  }

  .bgHack {
    background-color: $podium-cds-color-white;
    width: 194px;
    height: 194px;
    position: absolute;
    border-radius: 100px;
    margin: 5px 0 0 5px;
    box-shadow: -10px -5px 60px 5px rgba(255, 255, 255, 0.25);
  }

  .preview img {
    object-fit: cover;
    object-position: center center;
  }
}
