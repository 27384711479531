.paginateRow {
  width: auto;
  position: fixed;
  z-index: 999;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: var(--podium-cds-color-white);
  border: 1px solid var(--podium-cds-color-grey-200);
  border-radius: 10px;
  padding: var(--podium-cds-size-spacing-s);
  bottom: 10px;
  left: 50%;

  @media (max-width: var(--podium-cds-breakpoint-m)) {
    transform: translateX(-25%);
  }
}

.paginationPageEntry {
  padding: var(--podium-cds-size-spacing-m);
  padding: 5px;
  background-color: var(--podium-cds-color-grey-200);
  text-align: center;
}

.paginateLoadingOpacity {
  opacity: 0.4;
}

.paginateLoading {
  position: absolute;
  z-index: 1000;
  bottom: 10px;
  left: 50%;
}

.pagesRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.disabledIcon {
  opacity: 0.3;
}
