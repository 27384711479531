@import '@nike/nike-design-tokens/dist/scss/_tokens.scss';
@import 'constants/_variables';

.container {
  margin-top: var(--eds-space-32);
  margin-bottom: var(--eds-space-32);
  margin-left: var(--eds-space-32);
  margin-right: var(--eds-space-32);

  .navContainer {
    display: grid;
    grid-template-columns: 3fr 2fr;
    margin-bottom: var(--eds-space-12);

    .backButton {
      :hover {
        cursor: pointer;
      }
    }
  }
}

.title {
  font-size: 2rem;

  .pollsTitle {
    color: $podium-cds-color-grey-500;
  }
}

.choices {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
  grid-gap: 8px;
  position: relative;

  background-color: $podium-cds-color-white;
  width: 30vw;
  padding: var(--podium-cds-size-spacing-l);
  border-radius: var(--podium-cds-size-border-radius-m);
  background: var(--podium-cds-color-white);
  margin: var(--podium-cds-size-spacing-m) 0;
}
