.container {
  background-color: var(--eds-color-grey-5);
  padding: var(--eds-space-8) var(--eds-space-32);
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 24px;
}

.filter {
  background-color: var(--eds-color-grey-6);
  color: var(--eds-color-black);
  font: var(--eds-type-subtitle-2);
  padding: 4px 8px 4px 16px;
  border-radius: 4px;

  svg {
    padding-left: 8px;
  }

  svg,
  .all {
    color: var(--eds-color-grey-7);
  }
}

.selectAll {
  font: var(--eds-type-legal-1);
  margin: var(--eds-space-24) 0;

  &:focus {
    margin: calc(var(--eds-space-24) - 1px) 0;
  }
}

.actions {
  justify-content: right;
}
