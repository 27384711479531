.choice {
  background-color: var(--podium-cds-color-grey-100);
  border-radius: 8px;
  color: var(--podium-cds-color-black);
  font: var(--podium-cds-typography-body2);
  font-weight: 500;
  padding: 16px;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr min-content;

  & > span {
    position: relative;
    z-index: 2;
  }

  &.selected {
    .choiceLabel {
      font-weight: 700;
    }

    .percentBar {
      background-color: var(--podium-cds-color-grey-300);
    }
  }
}

.percentBar {
  background-color: var(--podium-cds-color-grey-200);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
