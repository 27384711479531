.container {
  hr {
    margin-top: var(--eds-space-32);
    margin-bottom: var(--eds-space-32);
    margin-left: var(--eds-space-16);
    margin-right: var(--eds-space-16);
    color: var(--eds-color-grey-3);
  }
  .topContainer {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-column-gap: var(--eds-space-16);
    grid-row-gap: var(--eds-space-16);
    margin-top: var(--eds-space-16);
    margin-left: var(--eds-space-16);
    margin-right: var(--eds-space-16);

    .nameInfoWrapper {
      height: 87px;
      margin-bottom: -16px;

      grid-column: 1 / 3; /* span from grid column line 1 to 3 (i.e., span 2 columns) */

      .nameInfoInnerWrapper {
        display: flex;
        height: inherit;

        .athleteAvatar {
          border: solid 16px var(--eds-color-white);
        }

        .nameAndUpdateByWrapper {
          margin-left: 16px;
        }

        .tier {
          padding-top: 12px;
          margin-left: 16px;

          span {
            background-color: var(--eds-color-orange-10);
          }
        }
      }
    }
  }

  .bottomContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: var(--eds-space-16);
    grid-row-gap: var(--eds-space-16);
    margin-top: var(--eds-space-16);
    margin-left: var(--eds-space-16);
    margin-right: var(--eds-space-16);
    margin-bottom: var(--eds-space-32);
  }

  .navContainer {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-column-gap: var(--eds-space-16);
    grid-row-gap: var(--eds-space-16);
    margin-top: var(--eds-space-16);
    margin-left: var(--eds-space-16);
    margin-right: var(--eds-space-16);
    margin-bottom: var(--eds-space-32);

    .backButton {
      :hover {
        cursor: pointer;
      }
    }
  }
}

.personalMantra {
  font-size: 1.2em;
  grid-row-end: span 2;
}

.pronouns {
  margin-bottom: var(--eds-space-32);
}

.tierChip {
  border-radius: 4px;
  padding: var(--eds-space-4);
}

.heading {
  background-color: var(--eds-color-grey-3);
}

.content {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: var(--eds-space-16);
  grid-auto-rows: auto;
}

.adminProfileCard {
  background-color: var(--eds-color-white);
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(17, 17, 17, 0.0784313725);
  padding: 8px;

  .topLeft {
    display: grid;
    grid-template-columns: 2fr 6fr;
    grid-column-gap: var(--eds-space-16);
    grid-row-gap: var(--eds-space-16);
    margin-top: var(--eds-space-16);
    margin-left: var(--eds-space-16);
    margin-right: var(--eds-space-16);

    .socialSection {
      div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: var(--eds-space-16);
        grid-row-gap: var(--eds-space-16);
        margin-top: var(--eds-space-72);

        a {
          svg {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }
}

.basicData {
  hr {
    margin-top: 8px;
    margin-bottom: 16px;
    margin-left: 0;
    margin-right: 0;
    color: var(--eds-color-grey-3);
  }
}

.basicDataReadOnly {
  .label {
    font-family: Helvetica Neue;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }

  .data {
    font-family: Helvetica Neue;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
}

.doubleRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--eds-space-16);
  grid-row-gap: var(--eds-space-16);
  margin-bottom: 16px;
}

.cardContent {
  padding: 16px;

  .basicDataReadOnly {
    padding-bottom: 16px;
  }

  hr {
    margin-top: 8px;
    margin-bottom: 16px;
    margin-left: 0;
    margin-right: 0;
    color: var(--eds-color-grey-3);
  }
}

.subHeading {
  font-family: Helvetica Neue;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  padding-bottom: 64px;
}

.equipmentSubHeading {
  font-family: Helvetica Neue;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  padding-bottom: 16px;

  .chip {
    position: relative;
    top: -3px;
    font-family: Helvetica Neue;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: center;
    background-color: var(--eds-color-orange-40);
    color: var(--eds-color-white);
    border-radius: 14px;
    padding: 4px;
  }
}

.innerSubHeading {
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  padding-bottom: 8px;
}

.interestsSection {
  p {
    display: none;
  }

  button {
    pointer-events: none;
    border-color: var(--podium-cds-color-grey-400);
    color: var(--podium-cds-color-black);

    span {
      color: var(--podium-cds-color-black);
    }
    &::before {
      border-color: var(--podium-cds-color-grey-400);
      color: var(--podium-cds-color-black);
    }
  }
}

.surveyQuestion {
  font-family: Helvetica Neue;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}

.surveyAnswer {
  font-family: Helvetica Neue;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 32px;
}
