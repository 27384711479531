button.button {
  display: inline-block;
  padding: 15px;
  margin: 5px;
  width: fit-content;

  span {
    vertical-align: middle;
  }

  svg {
    vertical-align: middle;
    padding-bottom: 2px;
  }

  &::before {
    border-color: inherit;
  }

  &.selected {
    background-color: var(--podium-cds-color-orange-400);
    border-color: var(--podium-cds-color-orange-400);
    span {
      color: var(--podium-cds-color-white);
    }

    path {
      fill: var(--podium-cds-color-white);
    }

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      background-color: var(--podium-cds-color-orange-300);
      border-color: var(--podium-cds-color-orange-300);

      &::before {
        border-color: inherit;
      }
    }
  }
}
