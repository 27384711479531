@import '@nike/nike-design-tokens/dist/scss/_tokens.scss';
@import 'src/constants/_variables';

.appBody {
  opacity: 1;
  transition: opacity $medTiming ease-in-out;
  background-color: $podium-cds-color-black;
  width: 100%;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.globalContainer {
  padding: $podium-cds-size-spacing-l;
  min-height: calc(100vh - 408px);
  @media (max-width: $podium-cds-breakpoint-l) {
    min-height: calc(100vh - 365px);
  }
}

.loader {
  // center loader
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.swoosh {
  width: 200px;
  height: 200px;
  svg {
    fill: white;
  }
}

.fadeOut {
  opacity: 0;
}
