.container {
  position: relative;
}

.icon {
  position: absolute;
  top: 8px;
  left: 12px;
  pointer-events: none;
  user-select: none;
}

.input {
  background-color: var(--eds-color-grey-6);
  border-radius: 24px;
  color: var(--eds-color-grey-1);
  font: var(--eds-type-subtitle-1);
  padding: 7px 49px 9px 51px;
  text-align: left;
  width: 100%;

  &::placeholder {
    color: var(--eds-color-grey-1);
    text-align: left;
  }
}
