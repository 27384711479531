@import '@nike/nike-design-tokens/dist/scss/_tokens.scss';

.container {
  height: calc(100vh - 84px);
  max-width: 100vw;
  overflow: auto;
  z-index: 1;
  padding-left: 10px;
}

.title {
  margin-bottom: 50px;
}

.table {
  position: relative;
  overflow: auto !important;
}

.tableRow {
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}

.headerRow {
  th {
    position: sticky;
    top: 0px;
    background-color: var(--podium-cds-color-grey-100);
    z-index: 2;
  }
}

.avatar {
  width: 75px;
  height: 75px;
  cursor: pointer;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
}

.interestsContainer {
  display: flex;
  max-width: 30vw;
  overflow: scroll;
}

.interest {
  display: inline-block;
  padding: 10px;
  border-radius: 2vh;
  width: fit-content;
  line-height: 22px;

  span {
    vertical-align: middle;
  }

  svg {
    vertical-align: middle;
    padding-right: 5px;
  }

  &::before {
    border-color: inherit;
  }

  background-color: $podium-cds-color-grey-100;
  display: flex;

  span {
    white-space: nowrap;
  }
}

.parent {
  display: inline-block;
  padding: 10px;
  border-radius: 2vh;
  width: 50px;
  line-height: 22px;
  text-align: center;
}

.yes {
  background-color: $podium-cds-color-green-100 !important;
}

.no {
  background-color: $podium-cds-color-red-100 !important;
}
