.wrapper {
  height: 100vh;
  width: 100vw;

  > div {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    grid-gap: 50px;

    img {
      height: 100px;
      width: auto;
    }
  }
}
