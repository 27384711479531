.swiper-pagination-bullet-active {
  background: white;
  animation: slideUpDisplay 450ms ease 2000ms forwards;
  animation-fill-mode: forwards;
  opacity: 0;
}

.swiper-pagination-bullet {
  animation: slideUpDisplay 450ms ease 2000ms forwards;
  animation-fill-mode: forwards;
  opacity: 0;
}

.swiper-slide {
  text-align: center;
}

@keyframes slideUpDisplay {
  from {
    opacity: 0;
    bottom: 10px;
  }

  to {
    opacity: 1;
    bottom: 0px;
  }
}
